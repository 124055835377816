import apiClient from './api'

export const authApi = {

    getToken: async () => {
        return await apiClient.get('api/customer/token');
    },

    phoneConfirmation: async (phone: string, token: string) => {
        try {
            const response = await apiClient.post(
                'api/customer/phone/request-confirmation', 
                {phone},
                {
                    headers: {
                      'Authorization': token 
                    }
                }
            )
            return response.data
        } catch (error) {
            console.log(error);
        }
    },

    phoneRequestConfirmation: async (code: string, token: string) => {
        console.log(code, token);
        
        try {
            const response = await apiClient.post(
                'api/customer/phone/confirmation', 
                {code},
                {
                    headers: {
                      'Authorization': token 
                    }
                }
            )
            return response
        } catch (error) {
            console.log(error);
        }
    },

    registration: async (name: string, lastname:string, birthday: string, password: string, password_confirmation: string, token: string) => {        
        try {
            const response = await apiClient.post(
                'api/customer/registration', 
                {name, lastname, birthday, password, password_confirmation},
                {
                    headers: {
                      'Authorization': token 
                    }
                }
            )
            return response
        } catch (error) {
            console.log(error);
        }
    },

    authenticate: async () => {
        // return await apiClient.get('api/authenticate');
        try {
            const response = await apiClient.get(
                'api/customer/authenticate', 
                {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                    }
                }
            )
            return response.data
        } catch (error) {
            console.log(error);
        }
    },

    login: async (phone: string, password: string) => {
        try {
            const response = await apiClient.post(
                'api/customer/login', 
                {phone, password}
            )
            console.log('login response', response);
            return response
        } catch (error) {
            console.log(error);
            throw error
        }
    },

    logout: async () => {
        try {
            const response = await apiClient.get('api/logout')
            return response.data
        } catch (error) {
            console.log(error)
        }
    },

    getQrCode: async (token: string) => {
        try {
            const response = await apiClient.get(
                'api/customer/qr/base64', 
                {
                    params: {
                        size: 500
                    },
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
                    }
                }
            )
            return response
        } catch (error) {
            console.log(error);
        }
    },
}