import {observable, action, makeAutoObservable} from "mobx"
import {authApi} from '../api/auth';

export interface User {
    id: number,
    phone: string,
    name: string,
    lastname: string,
    account_money: number,
    account_bonus: number
}

export class AuthStore {
    constructor() {
        makeAutoObservable(this)
    }

    @observable currentState: string = 'EnterPhone'
    // @observable currentState: string = ''
    @observable isAuth?: boolean = false
    @observable token: string = ''
    @observable accessToken: string = ''
    @observable phoneRequested?: boolean
    @observable codeRequested?: boolean
    @observable user?: User
    @observable errorMessages: string[] = []
    @observable qrCode: string = ''
    @observable authWait: boolean = false

    @action getToken = () => {        
        authApi
            .getToken()
            .then(res => {   
                console.log('getToken', res);             
                this.token = res.data.token
            })
            .catch(e => {
                console.log(e)
            })
    }

    @action getQrCode = () => {        
        authApi
            .getQrCode(this.accessToken)
            .then(res => {   
                console.log('getQrCode', res);    
                this.qrCode = res?.data         
            })
            .catch(e => {
                console.log(e)
            })
    }

    @action phoneConfirmation = (phone: string) => {
        authApi
            .phoneConfirmation(phone, this.token)
            .then(res => {
                console.log('phoneConfirmation', res);
                this.phoneRequested = res.requested;
                this.currentState = 'EnterCode'
            })
            .catch( e => {
                console.log(e);
            })
    }

    @action phoneRequestConfirmation = (code: string) => {
        authApi
            .phoneRequestConfirmation(code, this.token)
            .then(res => {
                console.log('phoneRequestConfirmation', res);
                
                // this.codeRequested = res.confirmed;
                this.currentState = ''
            })
            .catch( e => {
                console.log(e);
            })
    }

    @action registration = (name: string, lastname:string, birthday: string, password: string, password_confirmation: string) => {    
        authApi
            .registration(name, lastname, birthday, password, password_confirmation, this.token)
            .then(res => {
                console.log('registration', res);
            
                // this.codeRequested = res.requested;
                this.currentState = 'redirect'
            })
            .catch( e => {
                console.log(e);
            })
    }

    @action login = (phone: string, password: string) => {        
        authApi
            .login(phone, password)
            .then(response => {                
                let userData = response.data.user
                console.log('authstore response', userData);
                this.user = {
                    id: userData.id,
                    phone: userData.phone,
                    name: userData.name,
                    lastname: userData.lastname,
                    account_money: userData.account_money,
                    account_bonus: userData.account_bonus
                }
                this.accessToken = response?.data.access_token
                localStorage.setItem('accessToken', response?.data.access_token)
                this.isAuth = true
                // console.log('isAuth', this.isAuth);
                
                this.errorMessages = []
            }, reason => {
                console.log('reason', reason.response); 
                if (reason.response.data.message !== undefined) {
                    this.addErrorMessage(reason.response.data.message)
                } else {
                    this.addErrorMessage(reason.message)
                }
            })
            .catch( e => {
                if (e.response?.data.message !== undefined) {
                    this.addErrorMessage(e.response.data.message)
                } else {
                    this.addErrorMessage(e.message)
                }
                console.log(e);
            })
    }

    @action logout = () => {
        authApi
            .logout()
            .then(() => {
                this.isAuth = false
                this.user = undefined
            })
            .catch( e => {
                console.log(e)
            })

    }

    @action authenticate = () => {
        this.isAuth = false
        this.authWait = true

        authApi
            .authenticate()
            .then( response => {
                console.log('authenticate', response);
                
                if (response?.isAuth === true) {
                    this.isAuth = true
                    this.user = response.user
                } else {
                    this.isAuth = false
                }
                
                this.authWait = false
            })
    }

    @action addErrorMessage = (message: string) => {
        this.errorMessages.push(message)
        console.log(this.errorMessages)
    }

}