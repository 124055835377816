import React from 'react'
import {observer} from "mobx-react-lite"
import { Dropdown } from 'react-bootstrap'
import { FaGlobe } from 'react-icons/fa'
import i18n from "i18next"

const languages = [
    {
        code: 'ru',
        name: 'Русский',
        country_code: 'ru'
    },
    {
        code: 'en',
        name: 'English',
        country_code: 'us'
    }
]

const Header = observer(() => {
    return (
        <div className="header">
            <div className="w_container">
                <Dropdown>
                    <Dropdown.Toggle variant="link" id="dropdown-basic">
                        <FaGlobe style={{color: '#2B3E50'}}/>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {languages.map(({code, name, country_code}) => (
                            <Dropdown.Item
                                key={country_code}
                                onClick={() => i18n.changeLanguage(code)}
                            >
                                <span className={`flag-icon flag-icon-${country_code} mx-2`}/>
                                {name}
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
    )
})

export default Header