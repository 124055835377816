import React from "react"
import {RegisterStore} from "./stores/RegisterStore"
import {AuthStore} from "./stores/AuthStore"
import {PayStore} from "./stores/PayStore"
import {BrandStore} from "./stores/BrandStore"

type RootStateContextValue = {
    registerStore: RegisterStore,
    authStore: AuthStore,
    payStore: PayStore,
    brandStore: BrandStore
}

const RootStateContext = React.createContext<RootStateContextValue>({} as RootStateContextValue)

const RootStateValue: RootStateContextValue = {
    registerStore: new RegisterStore(),
    authStore: new AuthStore(),
    payStore: new PayStore(),
    brandStore: new BrandStore()
}


export const RootStateProvider: React.FC<React.PropsWithChildren<{}>> = ({children}) => {
    return (
        <RootStateContext.Provider value={RootStateValue}>
            {children}
        </RootStateContext.Provider>
    );
}

export const useRootStore = () => React.useContext(RootStateContext)