import React, { useState } from 'react'
import {observer} from "mobx-react-lite"
import 'react-tabs/style/react-tabs.css'
import sbp from '../../images/sbp.png'
import {useTranslation} from "react-i18next"
import {useRootStore} from '../../RootStateContext'
import {FaRubleSign} from 'react-icons/fa'
import {BiLinkExternal} from 'react-icons/bi'
import Balance from './components/Balance'
import { Alert } from 'react-bootstrap'

const Sbp = observer(() => {
    const {t} = useTranslation()
    const {payStore} = useRootStore()
    const {getLink, sbpLink, sbpLoading} = payStore
    const [amount, setAmount] = useState('')
    
    const handleClick = () => {
        getLink(String(amount))
    }

    const validateAmount = (e: any) => {
        const regex = /^\d+(\.\d{0,2})?$/g
        const prevValue = amount
        if(e === '') {
            setAmount(e)
        } else {
            if (!regex.test(e)) {
                setAmount(prevValue)
            } else {
                setAmount(e)
            }  
        }     
    }

    return (
        <>
            <Balance/>

            <div className="enter_sum">
                <p>{t('sbp.enter')}</p>
                <div className='enter_sum__container'>
                    <input 
                        type="tel" 
                        className="w_input"
                        onChange={e => validateAmount(e.target.value)}
                        inputMode="numeric"
                        pattern="\d*"
                        value={amount}
                    />
                    <FaRubleSign size={30}/>
                </div>
            </div>

            <button 
                className="sbp"
                onClick={handleClick}
                disabled={sbpLoading}
            >
                <img src={sbp} alt=""/>
                <span className='sbp__text'>{t('sbp.name')}</span>
                {t('sbp.pay')}
            </button>

            {
                sbpLink.length > 0 && 
                <a
                    href={sbpLink} 
                    className="sbpLink"
                    target="_blank"
                    rel="noreferrer"
                >
                    Перейти к оплате
                    <BiLinkExternal/>
                </a>
            }
        </>
    )
})

export default Sbp