import apiClient from './api'

export const registerApi = {
    register: async (phone: string, password: string, name: string, surmane: string, date: Date, promocode: string) => {
        try {
            // await apiClient.get('sanctum/csrf-cookie')
            // const response = await apiClient.post('api/login', {email, password})
            // return response.data.user
            return true;
        } catch (e) {
            console.log(e)
        }
    },

    getCode: async (phone: string) => {
        try {
            // await apiClient.get('sanctum/csrf-cookie')
            return true;
        } catch (e) {
            console.log(e)
        }
    }
}