import {action, makeAutoObservable, observable} from "mobx"
import {paymentApi} from '../api/payment'


export class PayStore {
    constructor() {
        makeAutoObservable(this)
    }

    @observable sbpLink: string = ''
    @observable sbpLoading: boolean = false

    @action getLink = (amount: string) => {
        this.sbpLink = ''
        this.sbpLoading = true

        paymentApi
            .getLink(parseFloat(amount))
            .then( data => {
                console.log(data)
                this.sbpLink = data.url
                // window.open(data.url, '_blank', 'noopener,noreferrer');
                this.sbpLoading = false
                
            })
            .catch( e => console.log(e))
    }

}