import React, { useEffect } from 'react'
import {observer} from "mobx-react-lite"
import 'react-tabs/style/react-tabs.css'
import {useTranslation} from "react-i18next"
import {useRootStore} from "../../RootStateContext"
import noimg from '../../images/noimg.png'
import Balance from './components/Balance'

const Card = observer(() => {
    const {t} = useTranslation()
    const {authStore} = useRootStore()

    useEffect( () => {
        authStore.getQrCode()
    }, [])

    return (
        <>
            <Balance/>

            <div className="code">
                <img 
                    src={'data:image/png;base64,' + authStore.qrCode} 
                    alt="Не удалось получить QR-код" 
                    className="code__image"
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = noimg;
                    }}
                />
                <p className="code__text">{t('card.barcode')}</p>
            </div>
        </>
    )
})

export default Card