import React, {useState} from 'react'
import {useRootStore} from "../RootStateContext"
import Styles from './LoginForm.module.css'
import {observer} from "mobx-react-lite"
import DatePicker from 'react-date-picker'
import {Link, useNavigate} from "react-router-dom"
import {useTranslation} from "react-i18next"
const Modal = require("react-modal")

const RegisterForm = observer(() => {
    const {registerStore, authStore} = useRootStore()
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [code, setCode] = useState('')
    const [surname, setSurname] = useState('')
    const [date, setDate] = useState(new Date())
    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')
    const [promo, setPromo] = useState('')
    const [policy, setPolicy] = useState(false)
    const [modalIsOpen, setIsOpen] = useState(false)
    const [modalDataIsOpen, setDataIsOpen] = useState(false)
    const navigate = useNavigate()
    const openModalData = () => setDataIsOpen(true)
    const closeModalData = () => setDataIsOpen(false)
    const openModal = () => setIsOpen(true)
    const closeModal = () => setIsOpen(false)

    const {t} = useTranslation()

    const phoneFormSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        
        authStore.phoneConfirmation('+' + phone.replace(/\D/g, ''))
    }

    const codeFormSubmit = (e: React.FormEvent) => {
        e.preventDefault()

        authStore.phoneRequestConfirmation(code)
    }

    const registerFormSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        if (password.length < 6 && password !== passwordConfirm) return
        console.log(date.toISOString().substring(0, 10));
        

        authStore.registration(name, surname, date.toISOString().substring(0, 10), password, passwordConfirm)
        navigate("/login")
    }

    switch (authStore.currentState) {
        case 'EnterPhone':
            return <PhoneForm/>
        case 'EnterCode':
            return <CodeForm/>
    }

    function PhoneForm() {
        const phoneHandler = (e: any) => {
            let getInputNumbersValue = function (input: any) {
                return input.value.replace(/\D/g, '');
            }
    
            let input = e.target,
                inputNumbersValue = getInputNumbersValue(input),
                selectionStart = input.selectionStart,
                formattedInputValue = "";
    
            if (!inputNumbersValue) return setPhone("")
            if (input.value.length !== selectionStart) {
                if (e.data && /\D/g.test(e.data)) {
                    setPhone(inputNumbersValue);
                }
                return;
            }
    
            if (["7", "8", "9"].indexOf(inputNumbersValue[0]) > -1) {
                if (inputNumbersValue[0] === "9") inputNumbersValue = "7" + inputNumbersValue;
                let firstSymbols = (inputNumbersValue[0] === "8") ? "+7" : "+7";
                formattedInputValue = input.value = firstSymbols + " ";
                if (inputNumbersValue.length > 1) {
                    formattedInputValue += '(' + inputNumbersValue.substring(1, 4);
                }
                if (inputNumbersValue.length >= 5) {
                    formattedInputValue += ') ' + inputNumbersValue.substring(4, 7);
                }
                if (inputNumbersValue.length >= 8) {
                    formattedInputValue += '-' + inputNumbersValue.substring(7, 9);
                }
                if (inputNumbersValue.length >= 10) {
                    formattedInputValue += '-' + inputNumbersValue.substring(9, 11);
                }
            } else {
                formattedInputValue = '+' + inputNumbersValue.substring(0, 16);
            }
            setPhone(formattedInputValue);
        }

        const keyDown = (e: any) => {
            let inputValue = e.target.value.replace(/\D/g, '');
            if (e.keyCode === 8 && inputValue.length === 1) setPhone("")
        }

        return (
            <div className={Styles.loginFormWrapper}>
                <form name="getCodeForm" className="w_form w_form--center" onSubmit={phoneFormSubmit}>
                    <div className="w_row">
                        <input
                            className="w_input"
                            type="tel"
                            value={phone}
                            onChange={e => phoneHandler(e)}
                            onKeyDown={e => keyDown(e)}
                            placeholder={t('form.phoneNumber')}
                            required
                            autoFocus
                            inputMode='tel'
                        />
                        {/* <InputMask 
                            mask="+7 999 999 99 99" 
                            className="w_input" 
                            type="tel"
                            value={phone} 
                            placeholder={t('form.phoneNumber')}
                            onChange={(e) => setPhone(e.target.value)}
                        /> */}
                    </div>
                    <div className="w_row w_row--no-mb">
                        <label onClick={() => setPolicy(!policy)} className="w_label">
                            <input
                                className="w_checkbox"
                                type="checkbox"
                                required
                                checked={policy}
                                onChange={() => setPolicy(!policy)}
                            />
                            <div></div>
                            <span className="text text--blue text--sm">{t('register.policy.one')}</span>
                        </label>
                    </div>
                    <div className="mb-50">
                        <strong className="text text--blue text--sm" onClick={openModalData}>{t('register.policy.two')}</strong>
                    </div>
                    <div className="text text--blue text--sm mb">{t('register.policy.three')}</div>
                    <div className="w_row">
                        <button type="submit" className="w_button">{t('register.code')}</button>
                    </div>

                    {/* <div className="register">
                        <Link to="/login">Вернуться ко входу</Link>
                    </div> */}
                </form>
            </div>
        )
    }

    function CodeForm() {
        return (
            <div className={Styles.loginFormWrapper}>
                <form name="enterCodeForm" className="w_form w_form--center" onSubmit={codeFormSubmit}>
                    <div className="text text--blue text--bold mb">{t('register.policy.four')}</div>
                    <div className="w_row">
                        <input
                            className="w_input"
                            type="text"
                            value={code}
                            onChange={e => setCode(e.target.value)}
                            placeholder={t('form.code')}
                            required
                            autoFocus
                            inputMode='numeric'
                        />
                    </div>
                    <div className="w_row">
                        <button type="submit" className="w_button">{t('form.continue')}</button>
                    </div>
                </form>
            </div>
        )
    }

    return (
        <div className={Styles.loginFormWrapper}>
            <form onSubmit={registerFormSubmit}>
                <div>
                    <div className="w_row">
                        <input
                            className="w_input"
                            type="text"
                            value={name}
                            onChange={e => setName(e.target.value)}
                            placeholder={t('form.name')}
                            required
                        />
                    </div>
                    <div className="w_row">
                        <input
                            className="w_input"
                            type="text"
                            value={surname}
                            onChange={e => setSurname(e.target.value)}
                            placeholder={t('form.surname')}
                        />
                    </div>
                    <div className="w_row">
                        <DatePicker onChange={setDate} value={date}/>

                    </div>
                    <div className="w_row">
                        <input
                            className="w_input"
                            type="password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            placeholder={t('form.password')}
                            required
                        />
                    </div>
                    <div className="w_row">
                        <input
                            className="w_input"
                            type="password"
                            value={passwordConfirm}
                            onChange={e => setPasswordConfirm(e.target.value)}
                            placeholder={t('form.confirmPassword')}
                            required
                        />
                    </div>
                    <div className="w_row">
                        <input
                            className="w_input"
                            type="text"
                            value={promo}
                            onChange={e => setPromo(e.target.value)}
                            placeholder={t('form.promocode')}
                        />
                    </div>
                    <div className="w_row">
                        <button type="submit" className="w_button">{t('form.goToRegisterPage')}</button>
                    </div>
                    <div className="w_row">
                        <label onClick={() => {setPolicy(!policy)}} className="w_label">
                            <input
                                className="w_checkbox"
                                type="checkbox"
                                required
                                checked={policy}
                                onChange={() => setPolicy(!policy)}
                            />
                            <div></div>
                            <span className="text text--blue text--sm">{t('register.policy.one')}</span>
                        </label>
                        <strong onClick={openModal} style={{fontSize: '11px', textAlign: 'center'}}>{t('register.policy.two')}</strong>
                    </div>
                </div>
            </form>
        </div>
    )

})

export default RegisterForm