import React from 'react'
import {observer} from "mobx-react-lite"
import 'react-tabs/style/react-tabs.css'
import {useTranslation} from "react-i18next"

const PurchaseList = observer(() => {
    const {t} = useTranslation()

    return (
        <>
            {/* <h2>{t('purchaseList.title')}</h2> */}
            <div className='purchasebg'>
                <table className="w_table">
                    <thead>
                        <tr>
                            <th>Дата</th>
                            <th>Сумма</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{new Date(Date.now()).toLocaleDateString('ru-RU')}</td>
                            <td>
                                <span className="plus">+100р</span>
                            </td>
                        </tr>
                        <tr>
                            <td>15/01/03</td>
                            <td>
                                <span className="minus">-100р</span>
                            </td>
                        </tr>
                        <tr>
                            <td>15/01/03</td>
                            <td>
                                <span className="plus">+100р</span>
                            </td>
                        </tr>
                        <tr>
                            <td>15/01/03</td>
                            <td>
                                <span className="minus">-100р</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
})

export default PurchaseList