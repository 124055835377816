import React, {Suspense} from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'
import {RootStateProvider} from "./RootStateContext"
import i18n from "i18next"
import {initReactI18next} from "react-i18next"
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend'
import {Spinner} from "react-bootstrap"
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(HttpApi)
    .init({
        supportedLngs: ['ru', 'en'],
        fallbackLng: 'ru',
        detection: {
            order: ['cookie'],
            caches: ['cookie']
        },
        backend: {
            loadPath: '/assets/locales/{{lng}}/translation.json',
        }
    })

const loading = (
    <div
        style={{
            position: 'fixed',
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}
    >
        <Spinner
            animation="border"
        />
    </div>
)

ReactDOM.render(
    <Suspense fallback={loading}>
        <React.StrictMode>
            <RootStateProvider>
                <App/>
            </RootStateProvider>
        </React.StrictMode>
    </Suspense>,
    document.getElementById('root')
)

serviceWorkerRegistration.register()
reportWebVitals()
