import React from 'react'
import {observer} from "mobx-react-lite"
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import {FaListAlt, FaRegListAlt} from 'react-icons/fa'
import {RiAccountCircleFill, RiAccountCircleLine} from 'react-icons/ri'
import Card from "./account/Card"
import PurchaseList from "./account/PurchaseList"
import Sbp from "./account/Sbp"
import {ReactComponent as SpbIconActive} from '../images/replenish.svg'
import {ReactComponent as SpbIconNotActive} from '../images/replenish_w.svg'

const Account = observer(() => {
    return (
        <div className="w_container">
            <div className="account">
                <Tabs>
                    <TabList>
                        <Tab>
                            <RiAccountCircleFill size={25} className="active"/>
                            <RiAccountCircleLine size={25} className="not_active"/>
                            <p>Аккаунт</p>
                        </Tab>
                        <Tab>
                            <SpbIconActive className='active'/>
                            <SpbIconNotActive className='not_active'/>
                            <p>Пополнение</p>
                        </Tab>
                        <Tab>
                            <FaListAlt size={25} className="active"/>
                            <FaRegListAlt size={25} className="not_active"/>
                            <p>Покупки</p>
                        </Tab>
                    </TabList>

                    <TabPanel>
                        <Card/>
                    </TabPanel>
                    <TabPanel>
                        <Sbp/>
                    </TabPanel>
                    <TabPanel>
                        <PurchaseList/>
                    </TabPanel>
                </Tabs>
            </div>
        </div>
    )
})

export default Account