import React, {useState, useEffect} from 'react'
import {useRootStore} from "../RootStateContext"
import Styles from './LoginForm.module.css'
import {observer} from "mobx-react-lite"
import {Link, useNavigate} from "react-router-dom"
import {useTranslation} from "react-i18next"
import logo from '../images/vodobox2.png'

const LoginForm = observer(() => {
    const {t} = useTranslation()
    const {authStore, brandStore} = useRootStore()
    const {brand} = brandStore
    const [phone, setPhone] = useState('')
    const [phoneError, setPhoneError] = useState(false)
    const [passwordError, setPasswordError] = useState(false)
    const [password, setPassword] = useState('')
    const navigate = useNavigate()

    useEffect(() => {
        if(authStore.isAuth) {
            navigate('/account')
        }
    }, [authStore.isAuth])

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault() 
               
        if(!phone.length) {
            setPhoneError(true)
            console.log('setPhoneError', phoneError);
            
            if(!password.length) {
                setPasswordError(true)
                return
            }
            return
        }
        authStore.login('+' + phone.replace(/\D/g, ''), password)
    }

    const phoneHandler = (e: any) => {
        let getInputNumbersValue = function (input: any) {
            return input.value.replace(/\D/g, '')
        }

        let input = e.target,
            inputNumbersValue = getInputNumbersValue(input),
            selectionStart = input.selectionStart,
            formattedInputValue = ""

        if (!inputNumbersValue) return setPhone("")
        if (input.value.length !== selectionStart) {
            if (e.data && /\D/g.test(e.data)) {
                setPhone(inputNumbersValue)
            }
            return
        }

        if (["7", "8", "9"].indexOf(inputNumbersValue[0]) > -1) {
            if (inputNumbersValue[0] === "9") inputNumbersValue = "7" + inputNumbersValue
            let firstSymbols = (inputNumbersValue[0] === "8") ? "+7" : "+7"
            formattedInputValue = input.value = firstSymbols + " "
            if (inputNumbersValue.length > 1) {
                formattedInputValue += '(' + inputNumbersValue.substring(1, 4)
            }
            if (inputNumbersValue.length >= 5) {
                formattedInputValue += ') ' + inputNumbersValue.substring(4, 7)
            }
            if (inputNumbersValue.length >= 8) {
                formattedInputValue += '-' + inputNumbersValue.substring(7, 9)
            }
            if (inputNumbersValue.length >= 10) {
                formattedInputValue += '-' + inputNumbersValue.substring(9, 11)
            }
        } else {
            formattedInputValue = '+' + inputNumbersValue.substring(0, 16)
        }
        setPhone(formattedInputValue)
    }

    const keyDown = (e: any) => {
        let inputValue = e.target.value.replace(/\D/g, '')
        if (e.keyCode === 8 && inputValue.length === 1) setPhone("")
    }
    
    return (
        <div className={Styles.loginFormWrapper}>
            <div className="logo">
                {
                    brand?.logotype.length ? 
                    <img src={'data:image/png;base64,' + brand?.logotype} alt="" width="240"/> :
                    <div style={{width: '240px', height: '240px'}}></div>
                }
            </div>
            <form className="w_form" onSubmit={handleSubmit}>
                <div>
                    <div className="w_row">
                        <input 
                            className={"w_input " + (phoneError ? "w_input--warn" : "")} 
                            type="tel" 
                            value={phone} 
                            onChange={e => {setPhoneError(false); phoneHandler(e)}}
                            onKeyDown={e => keyDown(e)}
                            placeholder={t('form.phoneNumber')}
                            data-tel-input
                            inputMode='tel'
                            autoComplete='tel'
                        />
                    </div>
                    <div className="w_row">
                        <input 
                            className={"w_input " + (passwordError ? "w_input--warn" : "")}  
                            type="password" 
                            value={password}
                            onChange={(e) => {setPasswordError(false); setPassword(e.target.value)}}
                            placeholder={t('form.password')}
                            autoComplete='current-password'
                        />
                    </div>
                    <div className="w_row">
                        <button className="w_button" type="submit">{t('form.enter')}</button>
                    </div>
                </div>

                <div className="register">
                    <Link to="/register">{t('form.goToRegisterPage')}</Link>
                </div>

                <div className="w_form__error">{authStore.errorMessages?.map(message => (<div key={message}>{message}</div>))}</div>
            </form>
        </div>
    )

})

export default LoginForm