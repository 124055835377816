import apiClient from './api'

export const brandApi = {
    getBrand: async () => { 
        try {
            const response = await apiClient.get(
                'api/customer/brand', 
                {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
                    }
                }
            )
            return response.data
        } catch (error) {
            console.log(error);
        }
    }
}