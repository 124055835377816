import React, {useEffect, Fragment} from 'react'
import {observer} from "mobx-react-lite"
import {useRootStore} from "./RootStateContext"
import LoginForm from "./components/LoginForm"
import RegisterForm from "./components/RegisterForm"
import Account from "./components/Account"
import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom"
import Header from "./components/Header"

const App = observer(() => {
    const {authStore, brandStore} = useRootStore()

    useEffect( () => {
        brandStore.getBrand()
        authStore.getToken()
        authStore.authenticate()
    }, [])

    function HomePage() {
        if (!authStore.isAuth) {
            return <Navigate to="/login" />
        }
        return <Navigate to="/account" />
    }
    return (
        <BrowserRouter>
            <Fragment>
                <Header/>

                <Routes>
                    <Route path="/" element={<HomePage/>}/>
                    <Route path="/login" element={<LoginForm/>}/>
                    <Route path="/register" element={<RegisterForm/>}/>
                    <Route path="/account" element={<Account/>}/>
                </Routes>
            </Fragment>
        </BrowserRouter>
    );
})

export default App