import apiClient from './api'

export const paymentApi = {
    getLink: async (amount: number) => { 
        console.log('getLink', amount);
               
        try {
            const response = await apiClient.post(
                'api/customer/sbp', 
                {amount},
                {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
                    }
                }
            )
            return response.data
        } catch (error) {
            console.log(error);
        }
    }
}