import {action, makeAutoObservable, observable} from "mobx"
import {brandApi} from '../api/brand'

interface Brand {
    logotype: string,
    name: string
}

export class BrandStore {
    constructor() {
        makeAutoObservable(this)
    }

    @observable brand?: Brand

    @action getBrand = () => {
        brandApi
            .getBrand()
            .then( data => {
                console.log('getBrand', data)
                this.brand = data
                document.title = data.name
            })
            .catch( e => console.log(e))
    }

}