import {observable, action, makeAutoObservable} from "mobx"
import {registerApi} from '../api/register';

export interface User {
    id: number,
    phone: string
}

export class RegisterStore {
    constructor() {
        makeAutoObservable(this)
    }

    @observable isAuth?: boolean
    @observable user?: User
    @observable errorMessages: string[] = []

    @action register = (phone: string, password: string, name: string, surname: string, date: Date, promocode: string) => {
        registerApi
            .register(phone, password, name, surname, date, promocode)
            .then( user => {

            })
            .catch( e => console.log(e))
    }

    @action getCode = (phone: string) => {
        registerApi
            .getCode(phone)
            .then()
            .catch(e => console.log(e))
    }
}