import React from 'react'
import {observer} from "mobx-react-lite"
import {Row, Button, Col} from 'react-bootstrap'
import {useRootStore} from "../../../RootStateContext"
import {useTranslation} from "react-i18next"
import {FaRubleSign} from 'react-icons/fa'
import {TbRefresh} from 'react-icons/tb'

const Balance = observer(() => {
    const {t} = useTranslation()
    const {authStore} = useRootStore()
    const {authenticate, authWait} = authStore

    return (
        <>
            <div className="balance">                
                <div className="balance__value">
                    <div className="balance__text">
                        {t('card.account_money')}

                        <Button 
                            className={'refresh_balance ' + (authWait ? 'refresh_balance--rotating' : '')}
                            onClick={authenticate}
                        >
                            {authWait}
                            <TbRefresh size={25}/>
                        </Button>
                    </div>

                    <div className="balance__data">
                        {authStore.user?.account_money} 
                        <FaRubleSign size={35}/>
                    </div>
                </div>

                <div className="balance__value">
                    <div className="balance__text">
                        {t('card.account_bonus')}
                    </div>

                    <div className="balance__data">
                        {authStore.user?.account_bonus} 
                    </div>
                </div>
            </div>
        </>
    )
})

export default Balance